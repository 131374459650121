import {BrowserModule, HAMMER_LOADER, HammerModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import { ToolbarComponent } from './includes/toolbar/toolbar.component';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { BodyComponent } from './includes/body/body.component';
import {RouterModule} from '@angular/router';
import { MainComponent } from './includes/main/main.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {AppRoutingModule} from './app-routing.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FooterComponent} from './includes/footer/footer.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    FooterComponent,
    BodyComponent,
    MainComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    MatSlideToggleModule,
    RouterModule,
    NgbModule,
    MatCardModule,
    MatButtonModule,
    FontAwesomeModule,
    HammerModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'nl-BE' },{
    provide: HAMMER_LOADER,
    useValue: async () => {
      return import('hammerjs/hammer');
    },
  }],
  bootstrap: [AppComponent],
})
export class AppModule { }
