import { Component, OnInit } from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import { faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  faHome = faHome;

  constructor() { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  switchTheme($event: MatSlideToggleChange) {
    if ($event.checked) {
      console.log('dark');
    } else {
      console.log('light');
    }
  }
}
