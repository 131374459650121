import {HttpErrorResponse} from '@angular/common/http';
import * as faBrands from '@fortawesome/free-brands-svg-icons';
import * as faSolid from '@fortawesome/free-solid-svg-icons';
import {IconDefinition} from '@fortawesome/free-regular-svg-icons';

// tslint:disable-next-line:ban-types
declare let gtag: Function;

export default class Utils {
  private static navigationStack: string[] = [];

  static url = () => {
    return `casper.wvg.be`;
  }

  static api = () => {
    return 'https://api.' + Utils.url() + '/';
  }

  static publicApi = () => {
    return Utils.api();
  }

  static setStorageItem = (ikey, ivalue) => {
    if (typeof(Storage) !== 'undefined') {
      localStorage.setItem(ikey, ivalue);
    }
  }

  static getStorageItem = (ikey) => {
    if (typeof(Storage) !== 'undefined') {
      return localStorage.getItem(ikey);
    }
    return null;
  }

  static removeStorageItem = (ikey) => {
    if (typeof(Storage) !== 'undefined') {
      localStorage.removeItem(ikey);
    }
  }

  static handleError(error: HttpErrorResponse): Error {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      console.error(error.error);
    }
    // return an observable with a user-facing error message
    return new Error('Something bad happened; please try again later.');
  }

  static getIcon(name): IconDefinition | undefined {
    return faBrands[name] || faSolid[name] || undefined;
  }

  static fromBigCharCode(str): string {
    const value = str.replace('&#', '').replace(';', '');
    // tslint:disable-next-line:radix
    const code = parseInt(value);
    if (isNaN(code)) {
      return str;
    }
    if (code > 0xFFFF) {
      const offset = code - 0x10000;
      // tslint:disable-next-line:no-bitwise
      return String.fromCodePoint(0xD800 + (offset >> 10), 0xDC00 + (offset & 0x3FF));
    }
    return String.fromCodePoint(code);
  }

  static HTMLDecode(str): string {
    return str.replaceAll(/&#([0-9])\w+;/g, this.fromBigCharCode);
  }

  static hasNavigated(): boolean {
    return this.navigationStack.length > 1;
  }

  static addToNavigationStack(url: string): number {
    if (this.navigationStack.length === 50) {
      this.navigationStack.shift();
    } else if (this.navigationStack.length > 50) {
      this.navigationStack = this.navigationStack.slice(-49); // To be safe
    }

    this.navigationStack.push(url);
    return this.navigationStack.length;
  }

  static getNavigationStack(): string[] {
    const stack: string[] = [];
    this.navigationStack.forEach(item => {
      stack.push(item);
    });
    return stack;
  }

  static getPreviousPage(): string {
    if (this.hasNavigated()) {
      return this.navigationStack[this.navigationStack.length - 2];
    }
    return '';
  }

  static sortByNumberInString(a: string, b: string, caseSensitive = false): number{
    if (!caseSensitive) {
      a = a.toLowerCase();
      b = b.toLowerCase();
    }
    let result = (a < b) ? -1 : (a > b) ? 1 : 0;
    if (result === 0 && !caseSensitive) {
      return Utils.sortByNumberInString(a, b, true);
    }
    const aParts = a.split('');
    const bParts = b.split('');
    let stop = false;
    while (aParts.length > 0 && bParts.length > 0 && !stop){
      const aTemp =  aParts.shift();
      const bTemp =  bParts.shift();
      if (aTemp !== bTemp) {
        stop = true;
        // tslint:disable-next-line:radix
        const aNumber = parseInt(aTemp + aParts.join(''));
        // tslint:disable-next-line:radix
        const bNumber = parseInt(bTemp + bParts.join(''));
        if (!isNaN(aNumber) && !isNaN(bNumber)) {
          result = (aNumber < bNumber) ? -1 : (aNumber > bNumber) ? 1 : 0;
        }
      }
    }
    return result;
  }

  static titleCaseWord(word: string): string {
    if (!word) { return word; }
    const result = word[0].toUpperCase() + word.substr(1).toLowerCase();
    return result.replace('wvg', 'WVG').replace('Wvg', 'WVG');
  }

  static captureOutBoundLink(url): void {
    gtag('send', 'event', 'outbound', 'click', url, {
      transport: 'beacon',
      hitCallback(): void {
        document.location = url;
      }
    });
  }

  static openUrl(url: string): boolean {
    if (url !== undefined && url.trim().length > 0) {
      url = url.trim();
      this.captureOutBoundLink(url);
      if ((url.startsWith('https://casper.wvg.be') || url.startsWith('https://www.casper.wvg.be')) && !url.includes('casper.wvg.be/assets/documents')) {
        window.open(url, '_self');
      } else {
        window.open(url, '_blank');
      }

      return true;
    }
    return false;
  }
}
