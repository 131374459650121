import { Component, OnInit } from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  year: string;

  constructor() { }

  ngOnInit(): void {
    this.year = new Date().getFullYear() + ' ';
  }
}
