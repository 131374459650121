<mat-toolbar class="mat-primary">
  <div class="d-flex justify-content-between container">
    <div class="d-flex justify-content-start align-middle">
      <fa-icon [icon]="faHome" [routerLink]="['/home']" [routerLinkActive]="['invisible']"></fa-icon>
    </div>
    <h1>CASPER HAEMS</h1>
    <div class="d-flex justify-content-end align-middle">
      <mat-slide-toggle class="mt-1 invisible" color="accent" [checked]="true" (change)="switchTheme($event)"></mat-slide-toggle>
    </div>
  </div>
</mat-toolbar>
