import { Component, OnInit } from '@angular/core';
import Utils from '../../Utils/utils';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  openUrl(url: string) {
    Utils.captureOutBoundLink(url);
    window.open(url, '_blank');
  }
}
