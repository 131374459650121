import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import Utils from './Utils/utils';
import {Meta, Title} from '@angular/platform-browser';

// tslint:disable-next-line:ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  title = 'Casper';
  private routerSubscription: Subscription;

  constructor(private router: Router,
              private titleService: Title,
              private metaService: Meta) {
  }

  ngAfterViewInit(): void {
    gtag('config', 'G-YQE8J4RQG8', {
      page_path: this.router.url
    });
    this.routerSubscription = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        const navigation = ev.urlAfterRedirects.split('/');
        let page = navigation[navigation.length - 1];

        const indexOfQuestionmark = page.indexOf('?');
        if (indexOfQuestionmark !== -1) {
          page = page.substr(0, indexOfQuestionmark);
        }

        const indexOfHashtag = page.indexOf('#');
        if (indexOfHashtag !== -1) {
          const id = (page.length > indexOfHashtag + 1) ? page.substr(indexOfHashtag + 1) : '#';
          page = page.substr(0, indexOfHashtag);
          this.smoothScrollingTo(id);
        } else {
          this.smoothScrollingTo('#', 100);
        }
        Utils.addToNavigationStack(ev.url);

        this.titleService.setTitle( Utils.titleCaseWord(page).replace(/-/gm, ' ') + ' - Casper');
        if (page === 'home') {
          this.updateDescription('PhD student in Information Engineering Technology');
        } else {
          this.updateDescription('Caspers project site');
        }


        gtag('set', 'page', ev.urlAfterRedirects);
        gtag('send', 'pageview');
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  smoothScrollingTo = (id: string, time = 500) => {
    id = (id === '#' || id === '') ? 'main' : id;
    setTimeout(() => {
      const target = document.getElementById(id);
      if (target && target instanceof HTMLElement){
        target.scrollIntoView({behavior: 'smooth'});
      }
    }, time);
  }

  private updateDescription(desc: string): void {
    this.metaService.updateTag({ name: 'description', content: desc });
  }
}
